import { memo, useEffect } from 'react'
import type { FC } from 'react'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { callLocales } from '@/i18n'
import { callAPI } from '@/api'
import { Layout, HeadTag } from '@/components'
import { HeaderMarquee, Button } from '@dy/commons/components'
import { getRelative, mq, getMargin200, getMargin50, getMargin20, getP26_1, getP18_1, createGrid } from '@/styles'

export interface ErrorProps {
  error?: any,
  initLocales?: any,
  onLoad?: any,
}

const TRANSLATIONS = {
  'en': {
    'seo': {
      title: 'HelloMamma | Page not found',
      description: 'Page not found.'
    },
    'page_not_fount': 'Page not found',
    'title': 'The page you are looking for does not exist',
    'description': 'Try browsing the web to see if you can find it. Or maybe you discover something much better, who knows.',
    'goback_homepage': 'Go back homepage'
  },
  'es': {
    'seo': {
      title: 'HelloMamma | Página no encontrada',
      description: 'Página no encontrada.'
    },
    'page_not_fount': 'Página no encontrada',
    'title': 'La página que estás buscando no existe',
    'description': 'Prueba de navegar por la web a ver si lo encuentras. O quizás descúbres algo mucho mejor, quien sabe.',
    'goback_homepage': 'Volver a la Home'
  },
  'fr': {
    'seo': {
      title: 'HelloMamma | Page non trouvée',
      description: 'Page non trouvée.'
    },
    'page_not_fount': 'Page non trouvée',
    'title': "La page que vous recherchez n'existe pas",
    'description': 'Essayez de naviguer sur le Web pour voir si vous pouvez le trouver. Ou peut-être que vous découvrez quelque chose de bien mieux, qui sait.',
    'goback_homepage': 'Retour à la page home'
  },
  'it': {
    'seo': {
      title: 'HelloMamma | Pagina non trovata',
      description: 'Pagina non trovata.'
    },
    'page_not_fount': 'Pagina non trovata',
    'title': 'La pagina che stai cercando non esiste',
    'description': 'Prova a navigare sul Web per vedere se riesci a trovarlo. O forse scopri qualcosa di molto meglio, chi lo sa.',
    'goback_homepage': 'Torna alla home page'
  }
}

const Main = styled.main`
  ${createGrid()}
  overflow-x: hidden;
  padding: 0;
  position: relative;

  ${mq.greaterThan('tablet')`
    overflow-x: inherit;
  `}

  section {
    grid-column: 1 / span 6;
    ${getMargin200()}
    padding: ${getRelative(50, 'mobile')} ${getRelative(20, 'mobile')};

    ${mq.greaterThan('tablet')`
      grid-column: 5 / span 4;
      padding: ${getRelative(60, 'desktop')} 0;
    `}

    h2, p {
      font-family: ${({ theme }) => theme.fonts.basier};
      text-align: center;
    }

    h2 {
      ${getP26_1()}
      ${getMargin20()}
      color: ${({ theme }) => theme.colors.greenlight};
      font-weight: 700 !important;
    }

    p {
      ${getP18_1()}
      ${getMargin50()}
      color: ${({ theme }) => theme.colors.greendark};
    }
  }
`

export async function getStaticProps({ locale }) {
  const [locales, layoutLocale] = await callLocales({ type: 'home', locale })
  const [layoutData] = await callAPI({ type: 'layout',  params: { locale } })

  return {
    props: {
      locales,
      layoutLocale,
      layout:layoutData
    },
    revalidate: 3600 // In seconds
  }
}

const NotFoundPage: NextPage<ErrorProps> & { Layout?:FC } = memo((props) => {
  const { onLoad } = props
  const { locale, push } = useRouter() || { locale: 'es', push: () => {} }

  useEffect(() => {
    if(onLoad) onLoad()
  }, [])

  const onClick = () => {
    push('/')
  }

  return (
    <>
      <HeadTag data={TRANSLATIONS[locale].seo} />
      <HeaderMarquee text={TRANSLATIONS[locale].page_not_fount}/>
      <Main>
        <section>
          <h2>{TRANSLATIONS[locale].title}</h2>
          <p>{TRANSLATIONS[locale].description}</p>
          <Button onClick={onClick} bgType='orange'>{TRANSLATIONS[locale].goback_homepage}</Button>
        </section>
      </Main>
    </>
  )
})

NotFoundPage.Layout = Layout
export default NotFoundPage
